<template>
    <DataDetails :Data="Data" :Groups="Groups" :Delta="Delta" :Account="Account" @deltachange="onDeltaChanged" />
</template>

<script>
import MetricService from "@/services/metrics";
import DataDetails from "@/components/common/DataDetails";

export default {
    name: "OverviewData",
    components: {
        DataDetails
    },
    emits: ["accountset"],
    data() {
        return {
            Account:null,
            Delta: 1,
            Data: null,
            Groups:[],
        }
    },
    service: null,
    created() {
        this.service = new MetricService()
    },
    watch: {
        Delta:function() {
            this.loadData()
        },
    },
    mounted() {
        this.Account = this.$router.currentRoute.value.params.account;
        this.loadData()
    },
    methods: {
        onDeltaChanged: function(delta) {
            this.Delta = delta
        },
        loadData() {
            this.service.getOverviewPim(this.Account, this.Delta).then(data => {
                let groups = {};
                for (const [, value] of Object.entries(data)) {
                    const grp = value["Field"]["Group"];

                    if (grp in groups) {
                        groups[grp].push(value)

                    } else {
                        groups[grp] = [value]
                        this.Groups.push(grp)
                    }
                }
                this.Data = groups
            })
        }
    }
}
</script>